.food-and-beverage-management {
  background: #fff;
 
  .top {
    .top-content {
      .title {
        color: #{$content-text-color};
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
 
      .description {
        color: #{$content-text-color};
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 144.023%;
      }

      .detail-description {
        color: #{$content-text-color};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 195.023%;
      }

      .food-and-beverage-management-img {
        width: 70%;
        height: auto;
        object-fit: contain;
        margin: 0 auto;
        display: block;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
  }

  .solutions {
    .solutions-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }

      .description {
        color: #{$content-text-color};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 195.023%; /* 39.005px */
      }

      .list {
        .list-group-item {
          background-color: transparent;
          border: none;
          color: #54595f;
          padding-left: 0;
          display: flex;
 
          .description {
            color: #{$content-text-color};
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 195.023%;
            margin: 0;
          }
 
          svg {
            margin-right: 10px;
            margin-top: 2px;
          }
        }
      }

      .mobile-column-reverse {
        @include media-breakpoint-down(lg) {
          display: flex;
          flex-direction: column-reverse; 
        }
      }
    }
  }

  .functions {
    .functions-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }

      .functions-img {
        width: 80%;
        height: auto;
        display: block;
        margin: 0 auto;
        object-fit: contain;

        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
  }
}
