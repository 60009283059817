.home {
  .top {
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }
  
    .top-content {
      position: relative;
      top: 63px;
  
      @include media-breakpoint-down(lg) {
        margin-top: 48px;
        top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 48px;
      }

      .top-banner {
        padding-bottom: 6rem;

        .demo-btn-wrapper {
          position: relative;
          padding-top: 3rem;
    
          .demo-btn {
            position: absolute;
          }

          @include media-breakpoint-down(lg) {
            margin-bottom: 6rem;
          }
        }
      }
  
      .description {
        color: #34C0B0;
        font-size: calc(1.275rem + .3vw);
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 700;
        line-height: 1.2;
        background: linear-gradient(90deg, #FFCF21, #FFF3CA);
        -webkit-text-fill-color: transparent;
        background-clip: text;
      }

      .sub-description {
        font-size: calc(1.275rem + .3vw);
        margin-top: 0;
        margin-bottom: 0.5rem;
        font-weight: 500;
        line-height: 1.2;
      }
    }
  }

  .product {
    .title {
      margin-bottom: 2.5rem;
      height: 60px;
      display: flex;

      @include media-breakpoint-down(lg) {
        .container {
          width: 100%;
        }
      }

      svg {
        margin-right: 9px;
      }

      .text {
        width: 100%;
        color: var(--bs-white);
        padding: 10px 10px 10px 0;

        .detail {
          position: relative;
          display: flex;
          height: 30px;

          @include media-breakpoint-down(lg) {
            justify-content: center;
          }
        }
      }

      img {
        color: transparent;
        display: inline-block;
        width: 61px;
        height: 88%;
        object-fit: cover;
        object-position: top left;

        @include media-breakpoint-down(lg) {
          object-fit: cover;
        }
      }
    }

    .row {
      --bs-gutter-x: 1rem !important;
      line-height: 1.5rem;
    }

    .card {
      --bs-card-spacer-x: 0;
      --bs-card-border-width: 0;
      --bs-card-border-radius: 9px;
      --bs-card-bg: var(--bs-white);
      --bs-card-height: 100%;
      border-radius: 6px;
      box-shadow: 1px 4px 4px 0px rgba(0, 0, 0, 0.25);

      &.card-badge {
        border: 3px solid yellow !important;
      }

      img {
        border-radius: 9px;
        height: var(--bs-card-height);
      }

      .badge {
        position: absolute;
        top: 10px;
        right: 10px;
      }

      .card-body {
        background-color: var(--bs-white);
        color: #{$custom-dropdown-color};
        font-size: 1.1rem;

        .card-title {
          color: #{$home-product-card-title-color};
          font-size: 1rem;

          .short-name {
            background-color: #{$home-product-color};
            color: var(--bs-white);
            padding: 15px;
            border-top-right-radius: 5px;
            border-top-left-radius: 5px;
            text-align: center;
            margin-bottom: 0;
            font-size: 1.5rem;
          }

          .name {
            background-color: #{$content-product-name-bg};
            color: var(--bs-white);
            padding: 6px;
            text-align: center;
            font-size: 0.8rem;
            min-height: 50px;
            align-items: center;
            display: flex;
            justify-content: center;
            margin-bottom: 0;
          }
        }

        .card-text {
          font-size: 0.9rem;
        }

        .more-btn {
          background-color: transparent;
          transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform .4s;
          border: none;
          border-radius: 0;
          padding: 12px 20px;
          color: #{$content-text-color};
          font-style: normal;
          font-weight: 700;
          line-height: normal;

          svg {
            margin-right: 6px;
          }
        }
      }
    }
  }

  .hotel-management {
    position: relative;

    .pms-img {
      object-fit: cover;
      object-position: top left;

      @include media-breakpoint-down(lg) {
        object-fit: cover;
      }
    }

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 62.2%;
      border-radius: 15%;
      top: 0;
      left: 0;
      position: absolute;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .divider {
      border-top: 1px solid var(--bs-white);
      width: 19%;
    }

    .list-group-item {
      background-color: transparent;
      border: none;
      padding-left: 0;
      color: var(--bs-white);

      svg {
        width: 1.25em;
        font-size: 2rem;
        margin-right: 6px;
      }
    }
  }

  .channel-manager {
    position: relative;
    background-size: 78rem;
    background-repeat: no-repeat;
    background-position-y: 15%;

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .title, .description {
      color: #{$home-title-color};
    }

    .divider {
      border-top: 1px solid #{$home-title-color};
      width: 40%;
    }

    .cooperations {
      img {
        cursor: pointer;
      }
    }

    .list-group-item {
      background-color: transparent;
      border: none;
      color: #{$global-color-secondary};
      padding-left: 0;

      svg {
        width: 1.25em;
        font-size: 19px;
        margin-right: 6px;
      }
    }
  }

  .clients {
    position: relative;

    .clients-img-bg {
      object-position: top right;
      object-fit: cover;

      @include media-breakpoint-down(lg) {
        height: 100% !important;
      }
    }

    @include media-breakpoint-down(xl) {
      .clients-img-bg {
        object-fit: cover;
      }
    }

    .container {
      .slide-client {
        padding: 8.5px;

        .info {
          padding: 10px;

          .address {
            line-height: 1.5;
            margin-top: 0px;
            word-wrap: break-word;
            box-sizing: border-box;
            min-height: 5.2rem;
          }
        }

        img {
          border: 6px solid var(--bs-white);
        }
      }
    }

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 86.7%;
      top: 0;
      right: 0;
      border-radius: 15%;
      position: absolute;

      @include media-breakpoint-down(md) {
        width: 100%;
      }
    }

    .divisions {
      background-color: var(--bs-white);
      opacity: 0.9999999;
      margin-top: 135px;

      .division-content {
        border-radius: 50px;
        box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
        background-color: var(--bs-white);
        bottom: 65px;
        position: relative;
        right: 130px;

        @include media-breakpoint-down(xl) {
          right: 0;
        }

        .card-container {
          display: flex;
          justify-content: center;
          align-items: center;
          --bs-gutter-x: 0;

          .card {
            --bs-card-spacer-x: 0;
            --bs-card-border-width: 0;
            --bs-card-border-radius: 9px;
            --bs-card-bg: var(--bs-white);
            --bs-card-height: 100%;
            align-items: center;
            max-width: 150px;
            border-radius: 50px;

            img {
              width: 143px;
              height: 143px;

              &.w-double {
                width: 250px;
                height: 170px;
              }
            }

            @include media-breakpoint-down(sm) {
              img {
                width: 113px;
  
                &.w-double {
                  width: 200px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .home {
    height: 100%;
  }
}