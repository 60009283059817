.hotel-booking-online {
  .top {
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .top-banner {
      position: relative;
      top: 63px;

      @include media-breakpoint-down(lg) {
        margin-top: 48px;
        top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 48px;
      }
    }

    .benefit-description {
      .icon {
        min-width: 40px;
      }
    }
  }
}