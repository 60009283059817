.technical {
  .top {
    position: relative;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .top-banner {
      position: relative;
      top: 63px;

      @include media-breakpoint-down(lg) {
        margin-top: 48px;
        top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 48px;
      }
    }
  }

  .minimum-requirements {
    .robot-img {
      position: relative;
      bottom: 65%;
      width: 120%;

      @include media-breakpoint-down(xl) {
        width: 120%;
        bottom: 46%;
      }

      @include media-breakpoint-down(lg) {
        width: 115%;
        bottom: 30%;
      }

      @include media-breakpoint-down(md) {
        width: 100%;
        bottom: 0;
      }
    }

    .workstation,
    .server,
    .premier-edition-server {
      --bs-table-bg: var(--bs-white);
      --bs-table-color: #{$content-third-text-color};
      --bs-table-striped-color: #{$content-third-text-color};
    }
  }

  .end-content {
    background-color: #{$content-light-bg};
  }
}