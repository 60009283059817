.hotel-management {
  background: #fff;
 
  .top {
    background: linear-gradient(180deg, #E3E3E4 0%, rgba(245, 245, 245, 0.00) 100%);
 
    .top-content {
      .title {
        color: #{$content-text-color};
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
 
      .description {
        color: #{$content-text-color};
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 144.023%;
      }

      .star-rating-text {
        color: #D08834;
        font-style: normal;
        font-weight: 700;
      }

      .detail-description {
        color: #{$content-third-text-color};
        font-style: normal;
        font-weight: 400;
        line-height: 195.023%;
        text-align: justify;
      }
 
      .hotel-management-img {
        width: 120%;
        height: 100%;
        object-fit: contain;
        position: relative;
        top: -50px;
 
        @include media-breakpoint-down(lg) {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
 
  .second {
    .second-content {
      border-radius: 52px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
 
      .card-container {
        display: flex;
        justify-content: center;
 
        .card {
          --bs-card-spacer-x: 0;
          --bs-card-border-width: 0;
          --bs-card-border-radius: 9px;
          --bs-card-bg: var(--bs-white);
          --bs-card-height: 100%;
          align-items: center;
          max-width: 290px;
  
          img {
            width: 143px;
            height: 143px;
          }
  
          .card-body {
            .card-title {
              color: #{$content-secondary-text-color};
              text-align: center;
              font-size: 30px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
              white-space: pre-line;
              margin-bottom: 0;
            }
          }
        }
      }
    }
  }
 
  .solutions {
    .solutions-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 24px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }
 
      .solutions-img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
 
      .solution3-img {
        width: 70%;
        height: 70%;
        margin-left: 100px;
        object-fit: contain;
 
        @include media-breakpoint-down(lg) {
          width: 100%;
          height: 100%;
          margin-left: 0;
        }
      }
 
      .list {
        .list-group-item {
          background-color: transparent;
          border: none;
          color: #54595f;
          padding-left: 0;
          display: flex;
 
          .description {
            color: #{$content-third-text-color};
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 195.023%;
            margin: 0;
          }
 
          svg {
            margin-right: 10px;
            margin-top: 5px;
          }
        }
      }
 
      .mobile-column-reverse {
        @include media-breakpoint-down(lg) {
          display: flex;
          flex-direction: column-reverse;
        }
      }
    }
  }
 
  .channel-manager {
    .channel-manager-content {
      .channel-manager-img {
        width: 100%;
        height: 100%;
      }
 
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }
 
      .list {
        .list-group-item {
          background-color: transparent;
          border: none;
          padding-left: 0;
          color: #{$content-third-text-color};
          font-size: 17px;
          font-style: normal;
          font-weight: 400;
          line-height: 195.023%;
 
          svg {
            margin-right: 10px;
          }
        }
      }
    }
  }
 
  .clients {
    .clients-content {
      .title {
        color: #{$ref-title-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }
 
      .clients-img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
