

.purchasing-management {
  color: #{$content-text-color};

  .purchase-requests {
    .subtitle {
      font-size: 20px;
      color: #{$content-text-color};
    }
  }
}