.accounting-management {
  background: #fff;

  .top {
    .top-content {
      .title {
        color: #{$content-text-color};
        font-size: 36px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
 
      .description {
        color: #{$content-text-color};
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 144.023%; /* 28.805px */
      }

      .list {
        .list-group-item {
          background-color: transparent;
          border: none;
          color: #54595f;
          padding-left: 0;
          display: flex;
 
          .description {
            color: #{$content-text-color};
            font-size: 17px;
            font-style: normal;
            font-weight: 400;
            line-height: 195.023%;
            margin: 0;
          }
 
          svg {
            margin-right: 10px;
            margin-top: 5px;
          }
        }
      }

      .accounting-management-img {
        width: 110%;
        height: auto;
        object-fit: contain;
        @include media-breakpoint-down(lg) {
          width: 100%;
        }
      }
    }
  }

  .synthetic-data {
    background: #F8F8F8;
    .synthetic-data-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }

      .description {
        color: #{$content-text-color};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 195.023%; /* 39.005px */
      }
    }
  }

  .cash-capital {
    .cash-capital-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }

      .description {
        color: #{$content-text-color};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 195.023%; /* 39.005px */
      }
    }
  }
  .receivables-and-payables-debt {
    .receivables-and-payables-debt-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }
    }
  }

  .tax {
    .tax-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }

      .tax-img {
        width: 80%;
        height: auto;
        object-fit: contain;
        float: right;

        @include media-breakpoint-down(lg) {
          width: 100%;
          float: left;
        }
      }

      .description-container {
        padding-top: 50px;
      }
    }
  }

  .fixed-assets {
    .fixed-assets-content {
      .title {
        color: #{$content-secondary-text-color};
        font-size: 32px;
        font-style: normal;
        font-weight: 700;
        line-height: 144.023%; /* 46.087px */
      }
    }
  }

  .mobile-column-reverse {
    @include media-breakpoint-down(lg) {
      display: flex;
      flex-direction: column-reverse; 
    }
  }

  .general-list {
    padding-left: 20px;

    .list-group-item {
      background-color: transparent;
      border: none;
      color: #54595f;
      padding-left: 0;
      padding-bottom: 0;
      display: list-item;
      list-style: circle;
      list-style-type: disc;

      .description {
        color: #{$content-text-color};
        font-size: 17px;
        font-style: normal;
        font-weight: 400;
        line-height: 195.023%;
        margin: 0;
      }
    }
  }

  .description-container {
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px 20px;

    .description {
      color: #{$content-text-color};
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: 174.523%; /* 41.885px */
      text-align: center;
      white-space: pre-line;
    }
  }
}
