.human-resource-management {
  .top-content {
    background: linear-gradient(180deg, #F7F8FE 0%, rgba(245, 245, 245, 0) 100%);

    .top-content-img {
      width: 50%;

      @include media-breakpoint-down(lg) {
        width: 100%;
      }
    }
  }

  .hrm-management,
  .timekeeping,
  .medical-insurance,
  .salary-by-productivity {
    background-color: #{$content-light-bg};
  }
}