$custom-dropdown-color: #7a7a7a;
$custom-dropdown-underline-color: #FFA403;
$global-color-secondary: #54595f;
$custom-form-field-color: #648185;
$custom-overlay-bg-color: #4CCCE9;
$content-text-color: #494040;
$content-secondary-text-color:  #556183;
$content-h2-title-color: #0D3970;
$demo-btn-bg: #0d6efd;
$demo-btn-active-bg: #f54;
$content-third-text-color: #212529;
$content-product-name-bg: #1C4E7D;
$content-main-bg: #0D3970;
$content-light-bg: #F8F8F8;
$home-product-card-title-color: #0A6E9E;
$home-title-color: #2F3656;
$ref-title-color: #556183;
$home-product-color: #2162C3;

:root {
  --bs-body-font-weight: 400;
  --bs-body-font-size: 1rem;

  .container-fluid {
    --bs-gutter-x: 0;
  }
}

[data-bs-theme=dihotel] {
  --bs-body-color: var($content-third-text-color);
  --bs-body-color-rgb: #{to-rgb($white)};
  --bs-body-bg: var(--bs-blue);
  --bs-body-bg-rgb: #{to-rgb($blue)};
  --bs-tertiary-bg: #{$blue-600};
  --bs-light-rgb: 248, 248, 248;

  .container-fluid {
    --bs-gutter-x: 100px;
  }

  .navigation-bar {
    .navbar-brand {
      .logo {
        width: 173px;
        height: 55px;
      }

      @include media-breakpoint-down(lg) {
        .logo {
          width: 125px;
          height: 40px;
        }
      }
    }

    .sp-language {
      .nav-dropdown-language-sp {
        margin-right: 6px;
      }
      
      .dropdown-toggle {
        background-color: transparent;
        border: none;
      }
    }
    
    .nav-lang {
      .dropdown-toggle {
        display: flex;
        align-items: center;
      }
    }

    div[data-language-switcher='true'] {
      padding: 13px 20px;
    }
  }

  @include media-breakpoint-down(lg) {
    .navigation-bar {
      --bs-gutter-x: 15px;
    }
  }

  .navbar {
    --bs-navbar-active-color: #{$custom-dropdown-underline-color};
    --bs-navbar-nav-link-padding-x: 15px;
    --bs-navbar-nav-link-padding-y: 13px;
    --bs-nav-underline-border-width: 3px;
    --bs-navbar-color: var(--bs-white);
    --bs-navbar-brand-margin-end: 2rem;
    --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 0.75)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");
    --bs-navbar-toggler-border-color: var(--bs-navbar-active-color);
    --bs-navbar-padding-y: 9px;

    background-color: #{$content-main-bg};
    color: var(--bs-white);

    &.is-transparent {
      background-color: transparent;
    }

    .navbar-toggler {
      color: var(--bs-navbar-active-color);
      border: none;
    }

    .nav-item {
      margin-bottom: 0.5rem;
    }
  }

  .navbar-nav {
    --bs-nav-link-color: var(--bs-white);
    --bs-nav-link-hover-color: var(--bs-navbar-active-color);
    --bs-nav-link-padding-x: 0.5rem;
    text-align: center;

    #nav-dropdown-solutions,
    .nav-item {
      .badge {
        margin-left: 10px;
        position: relative;
        bottom: 10px;
        margin-bottom: 3px;
      }
    }

    .dropdown-menu {
      .dropdown-badge {
        border: 3px solid yellow !important;

        .badge {
          margin-left: 10px;
          position: relative;
          bottom: 10px;
        }
      }
    }

    .header-phone, .header-email, .header-social-link {
      background: var(--custom-header-color);
      border: none;
      
      svg {
        width: 30px;
        height: 100%;
      }
    }
  }

  .nav-underline {
    .nav-link {
      font-weight: 600;
      
      &:not(.dropdown-toggle) {
        justify-content: center !important;
        display: flex !important;
      }

      &.active {
        line-height: 24px;
      }
    }

    .show {
      &> .nav-link {
        font-weight: 600;
        line-height: 24px;
      }
    }
  }

  .nav-pills {
    --bs-nav-pills-link-active-bg: transparent;
    --bs-nav-pills-border-radius: 0;
  }

  .sticky {
    z-index: 1;

    .navbar {
      background-color: #{$content-main-bg};
    }

    .nav-pills {
      --bs-nav-pills-link-active-bg: #{$content-main-bg};
    }
  }

  .dropdown-menu {
    --bs-dropdown-bg: var(--bs-white);
    --bs-dropdown-link-active-bg: #{$custom-dropdown-color};
    --bs-dropdown-link-active-color: var(--bs-white);
    --bs-dropdown-link-color: #{$custom-dropdown-color};
    --bs-dropdown-link-hover-bg: #{$custom-dropdown-color};
    --bs-dropdown-link-hover-color: var(--bs-white);
    --bs-dropdown-padding-y: 0;
    --bs-dropdown-spacer: 0;
    --bs-dropdown-border-radius: 0;
    --bs-dropdown-border-color: none;

    .dropdown-item {
      --bs-dropdown-item-padding-x: 20px;
      --bs-dropdown-item-padding-y: 13px;
    }
  }

  .btn-secondary {
    --bs-btn-bg: #{mix($gray-600, $blue-400, .5)};
    --bs-btn-border-color: #{rgba($white, .25)};
    --bs-btn-hover-bg: #{darken(mix($gray-600, $blue-400, .5), 5%)};
    --bs-btn-hover-border-color: #{rgba($white, .25)};
    --bs-btn-active-bg: #{darken(mix($gray-600, $blue-400, .5), 10%)};
    --bs-btn-active-border-color: #{rgba($white, .5)};
    --bs-btn-focus-border-color: #{rgba($white, .5)};
    --bs-btn-focus-box-shadow: 0 0 0 .25rem rgba(255, 255, 255, .2);
  }

  .carousel-indicators {
    [data-bs-target] {
      border-radius: 6px;
      width: 6px;
      height: 6px;
      border: none;
      margin-right: 6px;
      margin-left: 6px;
    }
  }

  @include media-breakpoint-down(lg) {
    .sticky-header {
      position: fixed;
      width: 100%;
      top: 0;
      display: contents;
  
      .navbar {
        background-color: #{$content-main-bg};
      }
    }
  }

  // Slick Slider
  .slick-slider {
    width: 100%;
    margin: 0 auto;

    div {
      &:focus {
        outline: 0;
      }
    }

    .slick-dots {
      display: none !important;

      li {
        width: 10px;
        height: 10px;

        button {
          &::before {
            color: var(--bs-white);
            opacity: 0.2;
          }
        }

        &.slick-active {
          button {
            &::before {
              opacity: 1;
              color: var(--bs-white);
            }
          }
        }
      }
    }
  }

  .form-control {
    border: 1px solid #{$custom-form-field-color};
    background: var(--bs-white);
    border-radius: 11px;
    padding: 10px;
    color: #{$custom-form-field-color};
  }

  main {
    .row {
      --bs-gutter-x: 3rem;
      line-height: 2rem;
    }

    &.fixed-header {
      margin-top: 48px;

      @include media-breakpoint-down(sm) {
        margin-top: 48px;
      }
    }

    .h1-title {
      color: #{$content-text-color};
    }

    .h2-title {
      color: #{$content-h2-title-color};
      font-weight: 700;
      padding-bottom: 1.5rem;
    }

    .subtitle {
      font-size: 1.5rem;
    }

    .img-common {
      width: 100%;
      height: auto;
    }

    .demo-btn {
      color: var(--bs-white);
      background-color: #{$demo-btn-bg};
      border-radius: 7px;
      box-shadow: none;
      text-decoration: none;
      padding: 0 1em;
      transition: opacity .3s,-webkit-transform .3s;
      transition: transform .3s,opacity .3s;
      transition: transform .3s,opacity .3s,-webkit-transform .3s;
      transition: background .3s,border .3s,border-radius .3s,box-shadow .3s,transform .4s;
      width: 160px;
      height: 48px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: none;
      font-size: 16px;
      line-height: 1;
      font-weight: 600;

      svg {
        margin-right: 6px;
      }

      &:hover {
        color: var(--bs-white);
        background-color: #{$demo-btn-active-bg};
      }
    }
  }

  footer {
    position: relative;

    .footer-top {
      .logo {
        justify-content: end;

        @include media-breakpoint-down(md) {
          justify-content: center;
        }
      }

      @include media-breakpoint-down(md) {
        .slogan,
        .description {
          text-align: center;
        }
      }
    }

    .show-border {
      border-right: var(--bs-border-width)
        var(--bs-border-style)
        var(--bs-border-color);

      @include media-breakpoint-down(lg) {
        border-right: none;

        .address-list {
          border-bottom: var(--bs-border-width)
            var(--bs-border-style)
            var(--bs-border-color);
        }
      }
    }

    .overlay {
      background-color: #{$content-main-bg};
      opacity: 0.9;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .divider {
      border-top: 1px solid var(--bs-white);
      width: 100%;
    }

    .address {
      .branch {
        display: grid;
        justify-content: center;
        align-items: center;

        @include media-breakpoint-down(lg) {
          display: block;
        }
      }

      .address-item {
        .icon {
          margin-top: 2px;
          min-width: 30px;
        }
      }

      svg {
        margin-right: 6px;
      }

      .branch-address, .branch-phone {
        span {
          font-family: inherit;
        }
      }
    }

    .product-name {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: calc(1.375rem + 1.5vw);
    }
    
    .description {
      margin-top: 0;
      margin-bottom: 0.5rem;
      font-weight: 500;
      line-height: 1.2;
      font-size: 1.25rem;
    }

    .triangle {
      top: 5px;
    }

    .copy-right {
      height: 64px;
      padding: 10px;
      background-color: #012647;
    }
  }

  .global-spinner {
    height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .package {
    position: relative;
    transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .top-banner {
      position: relative;
      top: 63px;

      @include media-breakpoint-down(lg) {
        margin-top: 48px;
        top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 48px;
      }
    }

    .demo-btn-wrapper {
      position: relative;
      padding-top: 3rem;

      .demo-btn {
        position: absolute;
      }

      @include media-breakpoint-down(lg) {
        margin-bottom: 6rem;
      }
    }
  }

  .text-justify {
    text-align: justify;
  }
}

.contact-us,
.contact-us-modal {
  .title {
    color: #{$home-title-color};
  }

  .row {
    --bs-gutter-x: 1.5rem !important;
  }

  .btn-contact-us {
    min-width: 180px;
    background-color: #3E566C;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    padding: 10px;

    svg {
      margin-right: 6px;
    }
  }
}

.contact-us-modal-wrapper {
  .modal-body {
    --bs-modal-padding: 3rem;

    @include media-breakpoint-down(md) {
      --bs-modal-padding: 1rem;
    }
  }

  .contact-us-modal {
    .caption {
      color: #{$content-h2-title-color};
      text-align: center;
    }

    .input-group {
      border: 1px solid #C0C0C0;
      background: var(--bs-white);
      border-radius: 11px;
      padding: 0;
      min-height: 3rem;

      .input-group-text {
        background-color: var(--bs-white);
        border-radius: 11px;
        border: none;

        svg {
          width: 1rem;
        }
      }

      .form-control {
        border: none;
        border-top-right-radius: 11px !important;
        border-bottom-right-radius: 11px !important;
      }
    }

    .btn-contact-us {
      border-radius: 11px;
      background: rgba(13, 57, 112, 0.90);
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    }
  }

  .modal-header {
    padding: 0;
    border: none;
    
    .btn-close {
      --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(500%);
      --bs-btn-close-bg: url(data:image/svg+xml,%3Csvg%20width%3D%2248%22%20height%3D%2248%22%20viewBox%3D%220%200%2048%2048%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2%2024C2%2011.8497%2011.8497%202%2024%202C36.1503%202%2046%2011.8497%2046%2024C46%2036.1503%2036.1503%2046%2024%2046C11.8497%2046%202%2036.1503%202%2024ZM18.073%2015.9515C17.4872%2015.3658%2016.5375%2015.3658%2015.9517%2015.9515C15.3659%2016.5373%2015.3659%2017.4871%2015.9517%2018.0729L21.8785%2023.9997L15.9517%2029.9265C15.3659%2030.5123%2015.3659%2031.4621%2015.9517%2032.0479C16.5375%2032.6337%2017.4872%2032.6337%2018.073%2032.0479L23.9998%2026.121L29.9267%2032.0479C30.5125%2032.6337%2031.4622%2032.6337%2032.048%2032.0479C32.6338%2031.4621%2032.6338%2030.5123%2032.048%2029.9265L26.1211%2023.9997L32.048%2018.0729C32.6338%2017.4871%2032.6338%2016.5373%2032.048%2015.9515C31.4622%2015.3658%2030.5125%2015.3658%2029.9267%2015.9515L23.9998%2021.8784L18.073%2015.9515Z%22%20fill%3D%22white%22%2F%3E%3C%2Fsvg%3E);
      --bs-btn-close-color: white;
      width: 48px;
      height: 48px;
      --bs-btn-close-opacity: 1;
      background: transparent var(--bs-btn-close-bg) center/2em auto no-repeat;
      position: absolute;
      right: -30px;
      top: -30px;
      padding: 0;
    }
  }
}

.top-banner-bg-img {
  object-fit: cover;
  object-position: center;

  @include media-breakpoint-down(lg) {
    object-fit: cover;
  }
}

.modal-50w {
  max-width: 530px;
  width: 50%;

  @include media-breakpoint-down(lg) {
    width: 90%;
  }
}

.ref-title {
  color: #{$ref-title-color};
}

.partners {
  background-color: var(--bs-white);
  opacity: 0.9999999;

  .partner-wrapper-title {
    justify-content: center;

    &::before {
      width: 0 !important;
    }

    @include media-breakpoint-down(md) {
      &::after {
        width: 0 !important;
      }
    }

    .title {
      color: #{$home-title-color};
      word-break: break-word;
    }
  }

  .partner-content {
    background-color: var(--bs-white);
    bottom: 65px;
    position: relative;

    @include media-breakpoint-down(xl) {
      right: 0;
    }

    .card-container {
      display: flex;
      justify-content: center;
      align-items: center;
      --bs-gutter-x: 0;
      border-radius: 50px;
      padding: 20px;
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

      .card {
        --bs-card-spacer-x: 0;
        --bs-card-border-width: 0;
        --bs-card-border-radius: 9px;
        --bs-card-bg: var(--bs-white);
        --bs-card-height: 100%;
        align-items: center;
        max-width: 100px;
        border-radius: 50px;
        cursor: pointer;
        margin: 20px 10px;

        img {
          width: 100px;

          &.w-double {
            width: 250px;
            height: 170px;
          }
        }

        @include media-breakpoint-down(sm) {
          img {
            width: 113px;

            &.w-double {
              width: 200px;
            }
          }
        }
      }
    }
  }
}