.news {
  .top {
    position: relative;

    .overlay {
      background-color: #{$custom-overlay-bg-color};
      opacity: 0.34;
      transition: background 0.3s, border-radius 0.3s, opacity 0.3s;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      position: absolute;
    }

    .top-banner {
      position: relative;
      top: 63px;

      @include media-breakpoint-down(lg) {
        margin-top: 48px;
        top: 0;
      }

      @include media-breakpoint-down(md) {
        margin-top: 48px;
      }
    }
  }

  .news-list {
    .news-item {
      --bs-gutter-x: 0;

      .number {
        font-size: 6rem;
        margin-right: 2.5rem;
        height: 5.5rem;
        align-items: center;
        color: #{$content-product-name-bg};

        @include media-breakpoint-down(md) {
          justify-content: center;
        }
      }

      .news-title {
        .title-link {
          font-size: 2rem;
          color: #{$content-secondary-text-color};
        }

        .MuiListItemText-secondary {
          line-height: 1.8rem;
          font-size: 1.1rem;
        }
      }
    }
  }

  .MuiTableFooter-root {
    * {
      font-size: 1.1rem !important;
    }
  
    .MuiTablePagination-selectLabel,
    .MuiTablePagination-displayedRows {
      margin-bottom: 0;
    }
  }

  .MuiPagination-ul {
    justify-content: center;
    border-top: solid #D9D9D9 2px;
    padding-top: 1.5rem;

    .MuiPaginationItem-root {
      background-color: #{$content-product-name-bg};
      color: var(--bs-white);
      border-radius: 11px;
      border-color: transparent;

      &.Mui-selected {
        color: #1976d2;
        background-color: rgba(25, 118, 210, 0.12);
      }
    }
  }

  .ref-links {
    a {
      color: #{$content-text-color};
      word-break: break-word;
    }
  }

  .figure {
    display: block;

    .figure-caption {
      color: #{$content-h2-title-color};
      font-size: 1.1rem;
      padding-top: 1rem;
    }
  }

  .source {
    a {
      color: #{$content-text-color};
    }
  }
}